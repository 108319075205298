/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import axios from 'axios'
import store from '@/store'
import {
  cannotAccessList,
  defaultPage
} from '@/assets/cannotAccessList';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: '登入',
      skipAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'AdminList',
    component: () => import('@/views/pages/admin/Admin.vue'),
    meta: {
      layout: 'content',
      title: '管理員資料',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['AdminList'],
    },
  },
  {
    path: '/admin/detail/:id',
    name: 'AdminDetail',
    component: () => import('@/views/pages/admin/AdminDetail.vue'),
    meta: {
      layout: 'content',
      title: '管理員詳細',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['AdminDetail'],
    },
  },
  {
    path: '/admin/create',
    name: 'CreateAdmin',
    component: () => import('@/views/pages/admin/AdminDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增管理員',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateAdmin'],
    },
  },
  {
    path: '/staff/detail/:id',
    name: 'adminStaffDetail',
    component: () => import('@/views/pages/admin/StaffDetail.vue'),
    meta: {
      layout: 'content',
      title: '員工詳細',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminStaffDetail'],
    },
  },
  {
    path: '/staff/create',
    name: 'adminStaffCreate',
    component: () => import('@/views/pages/admin/StaffDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增員工',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminStaffCreate'],
    },
  },
  {
    path: '/staff',
    name: 'adminStaff',
    component: () => import('@/views/pages/admin/Staff.vue'),
    meta: {
      layout: 'content',
      title: '員工資料',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminStaff'],
    },
  },
  {
    path: '/claim/create',
    name: 'adminClaimCreate',
    component: () => import('@/views/pages/admin/ClaimDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增報銷申請',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminClaimCreate'],
    },
  },
  {
    path: '/claim/detail/:id',
    name: 'adminClaimDetail',
    component: () => import('@/views/pages/admin/ClaimDetail.vue'),
    meta: {
      layout: 'content',
      title: '報銷費用詳細',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminClaimDetail'],
    },
  },
  {
    path: '/claim',
    name: 'adminClaim',
    component: () => import('@/views/pages/admin/Claim.vue'),
    meta: {
      layout: 'content',
      title: '報銷費用申請',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminClaim'],
    },
  },
  {
    path: '/leave/create',
    name: 'adminLeaveCreate',
    component: () => import('@/views/pages/admin/LeaveDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增假期申請',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminLeaveCreate'],
    },
  },
  {
    path: '/leave/detail/:id',
    name: 'adminLeaveDetail',
    component: () => import('@/views/pages/admin/LeaveDetail.vue'),
    meta: {
      layout: 'content',
      title: '假期申請詳細',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminLeaveDetail'],
    },
  },
  {
    path: '/leave',
    name: 'adminLeave',
    component: () => import('@/views/pages/admin/Leave.vue'),
    meta: {
      layout: 'content',
      title: '假期申請',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminLeave'],
    },
  },
  {
    path: '/check-in',
    name: 'CheckInList',
    component: () => import('@/views/pages/admin/CheckIn.vue'),
    meta: {
      layout: 'content',
      title: '打卡紀錄',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['CheckInList'],
    },
  },
  {
    path: '/salary/create',
    name: 'adminSalaryCreate',
    component: () => import('@/views/pages/admin/SalaryDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增薪金紀錄',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminSalaryCreate'],
    },
  },
  {
    path: '/salary/detail/:staff_id',
    name: 'adminSalaryDetail',
    component: () => import('@/views/pages/admin/SalaryDetail.vue'),
    meta: {
      layout: 'content',
      title: '薪金紀錄詳細',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminSalaryDetail'],
    },
  },
  {
    path: '/salary',
    name: 'adminSalary',
    component: () => import('@/views/pages/admin/Salary.vue'),
    meta: {
      layout: 'content',
      title: '薪金紀錄',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['adminSalary'],
    },
  },
  {
    path: '/news',
    name: 'NewsList',
    component: () => import('@/views/pages/admin/News.vue'),
    meta: {
      layout: 'content',
      title: '最新消息',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['NewsList'],
    },
  },
  {
    path: '/news/detail/:id',
    name: 'NewsDetail',
    component: () => import('@/views/pages/admin/NewsDetail.vue'),
    meta: {
      layout: 'content',
      title: '消息詳細',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['NewsDetail'],
    },
  },
  {
    path: '/news/create',
    name: 'CreateNews',
    component: () => import('@/views/pages/admin/NewsDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增消息',
      skipAuth: false,
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateNews'],
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/Error.vue'),
    meta: {
      title: '404',
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('toggleLoadingPage', false)
  if (to.meta.requireAuth) {
    // login cookie check
    let loginCookie = Cookies.get('KYEI_LOGISTICS_HR_CMS_LID')

    if (loginCookie !== undefined && loginCookie !== '') {
      loginCookie = JSON.parse(loginCookie)
      if (to.meta.cannotAccess !== undefined && to.meta.cannotAccess !== null && to.meta
        .cannotAccess.length > 0) {

        const params = new URLSearchParams()
        params.set('cms_get_admin_by_token', JSON.stringify({
          verify_token: loginCookie.verify_token
        }))

        const response = await axios.post('https://kyeilogistics.api.internal-staffsystem.com/hr/develop/request.php', params)

        if (response.data.result === 'success') {
          const data = response.data.data

          if (to.meta.cannotAccess.includes(data.admin_type)) {
            next(defaultPage[data.admin_type])
          } else {
            next()
          }
        } else {
          Cookies.remove('KYEI_LOGISTICS_HR_CMS_LID')
          next('/login')
        }
      } else {
        next()
      }

    } else if (to.path !== '/login') {
      next('/login')
    } else {
      next()
    }
  } else if (to.meta.skipAuth) {
    const loginCookie = Cookies.get('KYEI_LOGISTICS_HR_CMS_LID')
    if (loginCookie !== undefined && loginCookie !== '') {
      const data = JSON.parse(loginCookie);
      next(defaultPage[data.admin_type])
    } else {
      next()
    }
  } else {
    next()
  }
})

const DEFAULT_TITLE = 'Kyei Logistics HR CMS'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
  })
})

export default router
